.custom-branding__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.custom-branding .tide-upload-area-preview img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.custom-branding .tide-loading-icon {
  margin-left: 17px;
}

.custom-branding .tide-upload-area {
  width: 220px;
  margin-top: 2px;
}

.custom-branding section:nth-of-type(2) {
  margin-top: 32px;
  min-height: 60px;
}

.custom-branding section:nth-of-type(2) > div {
  max-width: 300px;
}

.custom-branding section:nth-of-type(3) {
  margin-top: 16px;
  max-width: 220px;
}

.custom-branding__upgrade {
  display: flex;
  justify-content: flex-end;
}

.custom-branding__upgrade button {
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  width: auto;
}

.custom-branding__upgrade button:active {
  outline: none;
}
