html,
body,
.global {
  height: 100%;
  margin: 0;
}

.settings-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: var(--color-p1-4);
}

.settings-wrapper .settings-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.settings-wrapper .tc-subnav-wrapper {
  height: 100vh;
}

.settings-wrapper .account-settings-card {
  height: 284px;
}

.settings-wrapper .loading-card-container {
  height: 284px;
}

.settings-wrapper .old-settings-page {
  width: 100%;
  height: 100%;
  border: none;
}

.video-main-nav + .settings-wrapper {
  margin-left: 48px;
}

#unified-root .settings-wrapper {
  margin-left: unset;
  width: 100%;
}
